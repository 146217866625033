/*
  import { questionItem } from '@/services/Surveys/QuestionModal/store'
*/

import Vue from 'vue'
import Vuex from 'vuex'
import { notifyProblem, notifyError, notifyMessage } from '@/services/notify'
import kms from '@/services/kms'
import _get from 'lodash/get'

const isDebug = true

/* eslint-disable no-empty-pattern */
const actions = {
  async getSurveyQuestionList({}, { surveySectionID }) {
    console.log('in getSurveyQuestionList...')
    try {
      const result = await kms.get('/Surveys/Question/List', {
        params: {
          surveySectionID
        }
      })

      if (isDebug == true) console.debug('getSurveyQuestionList log=' + JSON.stringify(result))

      return {
        list: result.results
      }
    } catch (exception) {
      notifyProblem(`The question list could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  async getQuestionTypeList({}) {
    console.log('in getQuestionTypeList...')
    try {
      const result = await kms.get('/Surveys/QuestionType/List')

      if (isDebug == true) console.debug('getQuestionTypeList log=' + JSON.stringify(result))

      return {
        list: result.results
          .map(v => ({
            value: v.questionTypeID,
            label: v.name ? v.name : ''
          }))
          .filter(f => f.label.length > 0 && f.value > 0)
          .sort((a, b) => a.label - b.label)
      }
    } catch (exception) {
      notifyProblem(`The question type list could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  async getQuestionList({}, { surveySectionID }) {
    console.log('in getQuestionList...')
    try {
      const result = await kms.get('/Surveys/Question/List', {
        params: {
          surveySectionID
        }
      })

      if (isDebug == true) console.debug('getQuestionList log=' + JSON.stringify(result))

      return {
        list: result.results
      }
    } catch (exception) {
      notifyProblem(`The question list could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },
  // Update Question
  async updateQuestion({ dispatch }, { payload = {}, done }) {
    try {
      const results = await kms.put(`/Surveys/Question`, payload)

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.questionID > 0) {
        notifyMessage(`Successfully updated the question.`)
        done({ details: results })
      } else {
        notifyError('There was a problem updating this question.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem processing the update for this question.`)
      console.error(exception)
    }
  },

  // Add Question
  async addQuestion(
    { dispatch },
    { surveySectionID, questionTypeID, name, isMultipleOption, done }
  ) {
    if (isDebug == true) console.debug('...in addQuestion')
    try {
      const results = await kms.post(`/Surveys/Question`, {
        surveySectionID,
        questionTypeID,
        name,
        isMultipleOption
      })

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.questionID > 0) {
        notifyMessage(`Successfully added the question.`)
        done({ details: results })
      } else {
        notifyError('There was a problem adding this question.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem adding this question.`)
      console.error(exception)
    }
  },

  async deleteQuestion({ dispatch }, { questionID, done }) {
    try {
      const path = `/Surveys/Question/${questionID}?forceDelete=false`

      const results = await kms.delete(path)

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      const wasDeleted = _get(results, 'deleted', false)

      if (wasDeleted === true) {
        notifyMessage(`Successfully deleted the question.`)
        done()
      } else {
        notifyError('There was a problem deleting the question.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem deleting this question.`)
      console.error(exception)
    }
  },

  async deleteOptionsByQuestion({ dispatch }, { questionID, forceDelete, done }) {
    try {
      const path = `/Surveys/Question/DeleteOptionsByQuestion?questionID=${questionID}&forceDelete=${forceDelete}`

      const results = await kms.delete(path)

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      const wasDeleted = _get(results, 'deleted', false)

      if (wasDeleted === true) {
        //notifyMessage(`Successfully deleted the question.`)
        done()
      } else {
        notifyError('There was a problem deleting the question options for this question.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem deleting the question option for this question.`)
      console.error(exception)
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const questionItem = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})
