<template>
  <div class="answers-results">
    <div
      question
      v-for="(question, indexA) in list"
      :key="indexA"
      :style="{
        justifyContent: 'space-between',
        ...(indexA !== list.length - 1 ? { marginBottom: '6px' } : {}),
        padding: '0px 10px'
      }"
    >
      <div class="pb-5 pr-6" :style="{ flexBasis: '33%' }">
        <div
          class="columns pb-5 pr-5 is-11"
          :style="{ color: color[8], height: '27px' }"
          v-if="question && question.name && question.name != undefined"
        >
          <div class="column is-8">
            <span
              class="pl-6 pr-5"
              style="vertical-align:text-bottom !important; padding-top:10px;"
            >
              <span class="pl-5">{{
                `(${processQuestionType(question.questionType.name)}) ${question.label}`
              }}</span>
            </span>
          </div>
          <div
            v-if="question.answerResult && question.answerResult != undefined"
            class="column is-3"
          >
            <span
              class="pl-1 pr-5"
              style="vertical-align:text-bottom !important; padding-top:10px;"
            >
              <span class="pl-5"
                ><span class="pl-1">{{
                  `${question.answerResult.answered || 0} Answered  (${question.answerResult
                    .percentResponded || 0}% Responded)`
                }}</span></span
              >
            </span>
          </div>
        </div>
        <div v-else :style="{ color: color[8] }">
          N/A
        </div>
      </div>

      <div
        question-options
        v-for="(questionOption, indexA) in question.questionOptions"
        :key="indexA"
        :style="{
          justifyContent: 'space-between',
          ...(indexA !== list.length - 1 ? { marginBottom: '6px' } : {}),
          padding: '0px 10px'
        }"
      >
        <div v-if="questionOption && questionOption.content" class="columns is-12 pl-6">
          <div
            class="pl-4 pr-5 column is-7"
            style="vertical-align:text-bottom !important; padding-top:10px;"
          >
            <span class="pl-4">
              <span class="pl-4">{{ `${questionOption.content}` }}</span
              ><span v-if="question.questionType.codeName == 'scale'">{{
                `: ${questionOption.scaleMin || 0} to ${questionOption.scaleMax || 0}`
              }}</span></span
            >
          </div>

          <div
            class="pl-6 pr-5 column is-4"
            style="vertical-align:text-bottom !important; padding-top:10px;"
          >
            <span class="pl-6">
              <span
                class="pl-6"
                v-if="question.questionType.codeName == 'text' && questionOption.answers"
              >
                <div>
                  <span class="pl-5"
                    ><span class="pl-5" v-if="questionOption.answers.length > 0">
                      <b-button
                        @click.prevent="
                          openTextAnswers(questionOption.questionOptionID, question.name)
                        "
                        class="is-primary"
                        >View Answers</b-button
                      >
                    </span>
                  </span>
                </div>
              </span>
              <span class="pl-5" v-else-if="question.questionType.codeName == 'scale'">
                <div
                  class="pl-5"
                  question-option-answers
                  v-for="(scale, indexA) in question.answerResult.optionResults"
                  :key="indexA"
                  :style="{
                    justifyContent: 'space-between',
                    ...(indexA !== list.length - 1 ? { marginBottom: '6px' } : {}),
                    padding: '0px 10px'
                  }"
                >
                  <span class="pl-5">
                    <span
                      :class="
                        scale.percentage == question.answerResult.maxScaleResultPercentage
                          ? 'boldanswer'
                          : 'normalanswer'
                      "
                      v-if="scale.selectedScaleResult"
                      >{{
                        `${scale.selectedScaleResult || 0}: ${
                          scale.numberAnswered
                        } (${scale.percentage || 0}%)`
                      }}</span
                    ></span
                  >
                </div>
              </span>
              <span
                v-else-if="
                  question.answerResult.optionResults[0] &&
                    question.answerResult.optionResults[0] != undefined &&
                    question.questionType.codeName == 'checkbox'
                "
                :class="
                  question.answerResult.mostAnsweredQuestionOptionID ==
                  questionOption.questionOptionID
                    ? 'boldanswer'
                    : 'normalanswer'
                "
                >{{
                  `${question.answerResult.optionResults.filter(
                    f => f.questionOptionID == questionOption.questionOptionID
                  )[0].numberAnswered || 0}`
                }}</span
              >
              <span
                v-else-if="
                  question.answerResult.optionResults[0] &&
                    question.answerResult.optionResults[0] != undefined
                "
                :class="
                  question.answerResult.mostAnsweredQuestionOptionID ==
                  questionOption.questionOptionID
                    ? 'boldanswer'
                    : 'normalanswer'
                "
                >{{
                  `${question.answerResult.optionResults.filter(
                    f => f.questionOptionID == questionOption.questionOptionID
                  )[0].numberAnswered || 0} (${question.answerResult.optionResults.filter(
                    f => f.questionOptionID == questionOption.questionOptionID
                  )[0].percentage || 0}%) `
                }}</span
              ></span
            >
          </div>
        </div>
      </div>
      <div
        class="pl-6"
        question-options-not-found
        v-if="!Array.isArray(question.questionOptions) || question.questionOptions.length === 0"
        :style="{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '6px',
          padding: '8px 12px',
          borderRadius: '6px',
          color: color[8]
        }"
      >
        <span class="pl-6"
          ><span class="pl-5">Question Options were not found for this question.</span></span
        >
      </div>
    </div>

    <div
      questions-not-found
      v-if="!Array.isArray(list) || list.length === 0"
      :style="{
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '6px',
        padding: '8px 12px',
        borderRadius: '6px',
        color: color[8]
      }"
    >
      Questions were not found.
    </div>

    <Modal :toggle.sync="toggle">
      <TextAnswerModal :questionOptionID="textQuestionOptionID" :questionName="questionName" />
    </Modal>
  </div>
</template>

<script>
import _get from 'lodash/get'
import { methods } from './keys/methods'
import TextAnswerModal from './components/TextAnswerModal'
import Modal from '@/components/Modal'

/*
  <Questions :questions="questions" />
*/
export default {
  props: {
    questions: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    const theme = _get(this, ['$store', 'state', 'theme'], {})

    return {
      color: theme.color,
      list: this.questions,
      selectedQuestion: null,
      selectedSurveySectionID: 0,
      selectedQuestionID: 0,
      questionTypeCodeName: '',
      toggle: false,
      textQuestionOptionID: 0,
      questionName: ''
    }
  },

  components: {
    TextAnswerModal,
    Modal
  },

  mounted() {},

  methods,

  watch: {
    questionToggle() {
      console.log('in toggle...')
      if (this.toggle == false) {
        this.reload(this.selectedSurveySectionID)
        this.questionToggle = false
        if (this.Modal && this.Modal != undefined) {
          this.Modal.$emit('update:toggle', (this.toggle = false))
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.answers-results {
  .boldanswer {
    font-weight: bold !important;
    color: blue !important;
  }

  .normalanswer {
    font-weight: normal;
    color: black !important;
  }
}
</style>
