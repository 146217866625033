import moment from 'moment'

export const data = () => ({
  isDebug: true,
  loading: false,
  surveyHeaderID: 0,
  surveyHeaderName: '',
  selectedSection: null,
  selectedSectionID: 0,
  sections: null,
  answerResult: null,
  formatDateFn: function(value) {
    return value != null ? moment(value, 'YYYY/MM/DD HH:mm:ss').format('MM-DD-YYYY') : ''
  },

  filters: {
    show: false
  },

  region: {},

  rows: [],

  columns: [
    {
      field: 'name',
      label: 'Name',
      aria: 'Name',
      width: '60%',
      sortable: true,
      searchable: true
    },
    {
      field: 'results',
      label: 'Results',
      aria: 'Results'
    }
  ],

  styles: {
    filterButton: {
      float: 'right',
      marginTop: '4px'
    }
  }
})
