<template>
  <div>
    <Questions :questions="questions" />
  </div>
</template>

<script>
import _get from 'lodash/get'
import Questions from './Questions'

export default {
  components: {
    Questions
  },
  props: {
    questions: Array
  },
  data() {
    const theme = _get(this, ['$store', 'state', 'theme'], {})

    return {
      color: theme.color
    }
  }
}
</script>
