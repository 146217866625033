import _get from 'lodash/get'
import { surveySectionList } from '@/services/Surveys/SurveySection/store'
import Details from './../components/Details'

export const methods = {
  rowOnClick() {},

  determineRows: function(list) {
    if (!Array.isArray(list)) {
      return
    }

    var rows = list.map(entry => {
      const surveySectionID = _get(entry, 'surveySectionID', 0)
      const name = _get(entry, 'name', '')
      let sectionQuestions = _get(entry, 'questions', null)

      if (sectionQuestions) {
        sectionQuestions = sectionQuestions.map(v => ({
          ...v,
          label: v.name ? v.name : '',
          answerResults: null
        }))

        console.log('list=' + JSON.stringify(sectionQuestions))

        let counter = 0
        Array.from(sectionQuestions).forEach(async e => {
          counter++
          if (e && e.name && e.name != undefined) {
            e.label = `${counter}. ${e.name}`
          }
        })
      }

      if (this.isDebug == true)
        console.debug(
          surveySectionID + 'questions and answers==' + JSON.stringify(sectionQuestions)
        )

      return {
        _expandable: {
          // expanded: false,

          // whether or not to show the expandable icon
          show: true,

          // the component to show when the expandable icon is pressed
          component: Details,

          // props to send to the expandable icon
          props: {
            questions: sectionQuestions
          }
        },
        name: name,
        results: ''
      }
    })

    this.rows = rows
  },

  async loadSurveySections() {
    if (this.isDebug == true) console.debug('in loadSurveySections()...')
    await surveySectionList
      .dispatch('getSurveySectionList', {
        surveyHeaderID: this.surveyHeaderID,
        includeAnswerResults: true
      })
      .then(({ list }) => {
        this.determineRows(list)
      })
  },

  returnToParent() {
    this.$router.push({
      path: '/communityCenter/surveyList'
    })
  },

  async reload() {
    this.loading = true

    this.surveyHeaderID = _get(this.$route.params, 'id', 0)

    this.surveyHeaderName = _get(this.$route.params, 'name', '')

    if (this.isDebug == true) console.debug('params surveyHeaderName=' + this.surveyHeaderName)

    this.rows = []

    await this.loadSurveySections()

    this.loading = false
  }
}
