export function data() {
  return {
    isDebug: true,
    isOpen: false,
    loading: true,
    textAnswers: [],
    styles: {
      formRow: {
        padding: '10px',
        whiteSpace: 'nowrap'
      }
    }
  }
}
