import { surveyResultList } from '@/services/Surveys/SurveyResults/store'
import $ from 'jquery'

export const methods = {
  async reload(questionOptionID) {
    this.loading = true

    await surveyResultList
      .dispatch('getAnswerList', {
        questionOptionID: questionOptionID
      })
      .then(({ list }) => {
        if (list) {
          this.textAnswers = list
        }
      })

    this.loading = false
  },

  open() {
    this.loading = false
  },

  leaveForm() {
    // auto-close modal
    this.$emit('update:toggle', (this.toggle = false))
    $('#clickclose').click()
    this.loading = false
  }
}
