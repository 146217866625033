/*
  import { surveyResultList } from '@/services/Surveys/SurveyResults/store'
*/

import Vue from 'vue'
import Vuex from 'vuex'
import kms from '@/services/kms'
import { notifyProblem } from '@/services/notify'
const isDebug = true

/* eslint-disable no-empty-pattern */
const actions = {
  /*
    const { list } = await surveyResultList.dispatch ('getSurveyResultsByQuestionId', {
      hoaId
    });
  */

  //Survey Results by Question ID
  async getSurveyResultsByQuestionId({}, { questionID }) {
    try {
      console.debug('in getSurveyResultsByQuestionId...' + questionID)
      const result = await kms.get('/Surveys/Answer/GetResultByQuestion', {
        params: {
          questionID
        }
      })

      if (isDebug == true) console.debug('getSurveyResultsByQuestionId=' + JSON.stringify(result))

      return {
        list: result
      }
    } catch (exception) {
      notifyProblem(`There was a problem retrieving the survey results.`)
      console.error(exception)
    }

    return {
      list: {}
    }
  },

  async getAnswerList({}, { questionOptionID }) {
    try {
      console.debug('in getAnswerList...' + questionOptionID)
      const result = await kms.get('/Surveys/Answer/List', {
        params: {
          questionOptionID
        }
      })

      if (isDebug == true) console.debug('getAnswerList=' + JSON.stringify(result))

      return {
        list: result.results
      }
    } catch (exception) {
      notifyProblem(`There was a problem retrieving the survey answers.`)
      console.error(exception)
    }

    return {
      list: {}
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const surveyResultList = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})
