import { questionItem } from '@/services/Surveys/QuestionModal/store'

export const methods = {
  processQuestionType(qtype) {
    if (qtype) {
      if (qtype === 'Radio-Button') {
        return 'Single Selection'
      } else if (qtype === 'Check-Box') {
        return 'Multiple Selections'
      } else if (qtype === 'Text') {
        return 'Text'
      } else if (qtype === 'Scale') {
        return 'Scale'
      } else {
        return qtype
      }
    }
  },

  async reload(sectionID) {
    this.selectedSurveySectionID = sectionID

    await questionItem
      .dispatch('getQuestionList', {
        surveySectionID: sectionID
      })
      .then(({ list }) => {
        if (list) {
          list = list.map(v => ({
            ...v,
            label: v.name ? v.name : ''
          }))

          let counter = 0
          Array.from(list).forEach(e => {
            counter++
            if (e && e.name && e.name != undefined) {
              e.label = `${counter}. ${e.name}`
            }
          })
        }
        this.list = list
      })
  },

  openTextAnswers(questionOptionID, name) {
    if (questionOptionID) {
      this.toggle = true
      this.textQuestionOptionID = questionOptionID
      this.questionName = name
    }
  }
}
