var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PageContentLayoutOnly',{attrs:{"title":_vm.$t('answer.title'),"role":"region"}},[_c('div',{staticClass:"title"},[_vm._v("Results - Survey Answers")]),_c('div',{staticClass:"subtitle"},[_vm._v(_vm._s(_vm.surveyHeaderName))]),_c('div',{staticClass:"level"},[_c('div',{staticClass:"level-right"},[_c('div',{staticClass:"level-item"},[_c('svg',{attrs:{"width":"30","height":"30","xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M15 19l-7-7 7-7"}})])]),_c('div',{staticClass:"level-item"},[_c('b-button',{attrs:{"label":"Back to Surveys"},on:{"click":function($event){$event.preventDefault();return _vm.returnToParent()}}})],1)])]),_c('ModernTable',{attrs:{"region":_vm.region,"filters":_vm.filters,"rows":_vm.rows,"columns":_vm.columns,"pagination":{
      perPage: 12
    },"loading":_vm.loading,"headerColor":"#FFF","expandableRows":true,"rowOnClick":_vm.rowOnClick,"_styles":{
      tableWrapper: {
        height: 'calc(100vh - 240px)',
        fontWeight: 'bold'
      }
    }},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{style:({ width: '100%', height: '60px' })},[_c('b-button',{class:'filter-button ' + (_vm.filters.show ? 'active' : ''),style:({ float: 'right', background: 'none', border: 0 }),attrs:{"type":"is-ghost"},on:{"click":function($event){_vm.filters.show = !_vm.filters.show}}},[_c('b-icon',{staticStyle:{"display":"inline-block"},attrs:{"pack":"mdi","icon":"filter-outline","size":"is-small"}}),_c('div',{staticStyle:{"display":"inline-block"}},[_vm._v("Filter")])],1)],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }