<template>
  <div text-answer-modal>
    <div class="is-size-4">Text Answers</div>
    <div class="subtitle">{{ questionName }}</div>
    <div v-if="textAnswers">
      <div
        question-option-answers
        class="pb-2"
        v-for="(answer, indexA) in textAnswers"
        :key="indexA"
        :style="{
          justifyContent: 'space-between',
          ...(indexA !== textAnswers.length - 1 ? { marginBottom: '6px' } : {}),
          padding: '0px 10px'
        }"
      >
        <span v-if="answer.answerComment && answer.answerComment != undefined">
          {{ answer.answerComment || '' }}
        </span>
      </div>
      <div class="pt-5 pb-5" style="overflow: auto;">
        <button
          type="button"
          :disabled="loading"
          class="button is-primary is-rounded"
          @click.prevent="leaveForm"
        >
          Close
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { data } from './keys/data'
import { methods } from './keys/methods'

export default {
  props: {
    questionOptionID: Number,
    questionName: String
  },

  components: {},

  mounted() {
    if (this.questionOptionID) {
      this.reload(this.questionOptionID)
    }
  },

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('user', ['authUser'])
    })
  },

  data,
  methods
}
</script>
