var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"answers-results"},[_vm._l((_vm.list),function(question,indexA){return _c('div',{key:indexA,style:(Object.assign({}, {justifyContent: 'space-between'},
      (indexA !== _vm.list.length - 1 ? { marginBottom: '6px' } : {}),
      {padding: '0px 10px'})),attrs:{"question":""}},[_c('div',{staticClass:"pb-5 pr-6",style:({ flexBasis: '33%' })},[(question && question.name && question.name != undefined)?_c('div',{staticClass:"columns pb-5 pr-5 is-11",style:({ color: _vm.color[8], height: '27px' })},[_c('div',{staticClass:"column is-8"},[_c('span',{staticClass:"pl-6 pr-5",staticStyle:{"vertical-align":"text-bottom !important","padding-top":"10px"}},[_c('span',{staticClass:"pl-5"},[_vm._v(_vm._s(("(" + (_vm.processQuestionType(question.questionType.name)) + ") " + (question.label))))])])]),(question.answerResult && question.answerResult != undefined)?_c('div',{staticClass:"column is-3"},[_c('span',{staticClass:"pl-1 pr-5",staticStyle:{"vertical-align":"text-bottom !important","padding-top":"10px"}},[_c('span',{staticClass:"pl-5"},[_c('span',{staticClass:"pl-1"},[_vm._v(_vm._s(((question.answerResult.answered || 0) + " Answered (" + (question.answerResult .percentResponded || 0) + "% Responded)")))])])])]):_vm._e()]):_c('div',{style:({ color: _vm.color[8] })},[_vm._v(" N/A ")])]),_vm._l((question.questionOptions),function(questionOption,indexA){return _c('div',{key:indexA,style:(Object.assign({}, {justifyContent: 'space-between'},
        (indexA !== _vm.list.length - 1 ? { marginBottom: '6px' } : {}),
        {padding: '0px 10px'})),attrs:{"question-options":""}},[(questionOption && questionOption.content)?_c('div',{staticClass:"columns is-12 pl-6"},[_c('div',{staticClass:"pl-4 pr-5 column is-7",staticStyle:{"vertical-align":"text-bottom !important","padding-top":"10px"}},[_c('span',{staticClass:"pl-4"},[_c('span',{staticClass:"pl-4"},[_vm._v(_vm._s(("" + (questionOption.content))))]),(question.questionType.codeName == 'scale')?_c('span',[_vm._v(_vm._s((": " + (questionOption.scaleMin || 0) + " to " + (questionOption.scaleMax || 0))))]):_vm._e()])]),_c('div',{staticClass:"pl-6 pr-5 column is-4",staticStyle:{"vertical-align":"text-bottom !important","padding-top":"10px"}},[_c('span',{staticClass:"pl-6"},[(question.questionType.codeName == 'text' && questionOption.answers)?_c('span',{staticClass:"pl-6"},[_c('div',[_c('span',{staticClass:"pl-5"},[(questionOption.answers.length > 0)?_c('span',{staticClass:"pl-5"},[_c('b-button',{staticClass:"is-primary",on:{"click":function($event){$event.preventDefault();return _vm.openTextAnswers(questionOption.questionOptionID, question.name)}}},[_vm._v("View Answers")])],1):_vm._e()])])]):(question.questionType.codeName == 'scale')?_c('span',{staticClass:"pl-5"},_vm._l((question.answerResult.optionResults),function(scale,indexA){return _c('div',{key:indexA,staticClass:"pl-5",style:(Object.assign({}, {justifyContent: 'space-between'},
                  (indexA !== _vm.list.length - 1 ? { marginBottom: '6px' } : {}),
                  {padding: '0px 10px'})),attrs:{"question-option-answers":""}},[_c('span',{staticClass:"pl-5"},[(scale.selectedScaleResult)?_c('span',{class:scale.percentage == question.answerResult.maxScaleResultPercentage
                        ? 'boldanswer'
                        : 'normalanswer'},[_vm._v(_vm._s(((scale.selectedScaleResult || 0) + ": " + (scale.numberAnswered) + " (" + (scale.percentage || 0) + "%)")))]):_vm._e()])])}),0):(
                question.answerResult.optionResults[0] &&
                  question.answerResult.optionResults[0] != undefined &&
                  question.questionType.codeName == 'checkbox'
              )?_c('span',{class:question.answerResult.mostAnsweredQuestionOptionID ==
                questionOption.questionOptionID
                  ? 'boldanswer'
                  : 'normalanswer'},[_vm._v(_vm._s(("" + (question.answerResult.optionResults.filter( function (f) { return f.questionOptionID == questionOption.questionOptionID; } )[0].numberAnswered || 0))))]):(
                question.answerResult.optionResults[0] &&
                  question.answerResult.optionResults[0] != undefined
              )?_c('span',{class:question.answerResult.mostAnsweredQuestionOptionID ==
                questionOption.questionOptionID
                  ? 'boldanswer'
                  : 'normalanswer'},[_vm._v(_vm._s(((question.answerResult.optionResults.filter( function (f) { return f.questionOptionID == questionOption.questionOptionID; } )[0].numberAnswered || 0) + " (" + (question.answerResult.optionResults.filter( function (f) { return f.questionOptionID == questionOption.questionOptionID; } )[0].percentage || 0) + "%) ")))]):_vm._e()])])]):_vm._e()])}),(!Array.isArray(question.questionOptions) || question.questionOptions.length === 0)?_c('div',{staticClass:"pl-6",style:({
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '6px',
        padding: '8px 12px',
        borderRadius: '6px',
        color: _vm.color[8]
      }),attrs:{"question-options-not-found":""}},[_vm._m(0,true)]):_vm._e()],2)}),(!Array.isArray(_vm.list) || _vm.list.length === 0)?_c('div',{style:({
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '6px',
      padding: '8px 12px',
      borderRadius: '6px',
      color: _vm.color[8]
    }),attrs:{"questions-not-found":""}},[_vm._v(" Questions were not found. ")]):_vm._e(),_c('Modal',{attrs:{"toggle":_vm.toggle},on:{"update:toggle":function($event){_vm.toggle=$event}}},[_c('TextAnswerModal',{attrs:{"questionOptionID":_vm.textQuestionOptionID,"questionName":_vm.questionName}})],1)],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"pl-6"},[_c('span',{staticClass:"pl-5"},[_vm._v("Question Options were not found for this question.")])])}]

export { render, staticRenderFns }