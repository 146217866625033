<template>
  <PageContentLayoutOnly :title="$t('answer.title')" role="region">
    <div class="title">Results - Survey Answers</div>
    <div class="subtitle">{{ surveyHeaderName }}</div>
    <div class="level">
      <div class="level-right">
        <div class="level-item">
          <svg
            width="30"
            height="30"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15 19l-7-7 7-7"
            ></path>
          </svg>
        </div>
        <div class="level-item">
          <b-button label="Back to Surveys" @click.prevent="returnToParent()" />
        </div>
      </div>
    </div>

    <ModernTable
      :region="region"
      :filters="filters"
      :rows="rows"
      :columns="columns"
      :pagination="{
        perPage: 12
      }"
      :loading="loading"
      headerColor="#FFF"
      :expandableRows="true"
      :rowOnClick="rowOnClick"
      :_styles="{
        tableWrapper: {
          height: 'calc(100vh - 240px)',
          fontWeight: 'bold'
        }
      }"
    >
      <template v-slot:header>
        <div :style="{ width: '100%', height: '60px' }">
          <b-button
            type="is-ghost"
            @click="filters.show = !filters.show"
            :style="{ float: 'right', background: 'none', border: 0 }"
            :class="'filter-button ' + (filters.show ? 'active' : '')"
          >
            <b-icon pack="mdi" icon="filter-outline" size="is-small" style="display: inline-block">
            </b-icon>
            <div style="display: inline-block">Filter</div>
          </b-button>
        </div>
      </template>
    </ModernTable>
  </PageContentLayoutOnly>
</template>

<script>
import { mapState } from 'vuex'
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
import ModernTable from '@/components/tables/Modern/Table'
import { methods } from './keys/methods'
import { data } from './keys/data'

export default {
  name: 'SurveySectionAnswers',
  components: {
    PageContentLayoutOnly,
    ModernTable
  },

  data,
  methods,

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId
    })
  },

  watch: {},

  mounted() {
    this.reload()
  },

  i18n: {
    messages: {
      en: { answer: { title: 'Survey Results - Answers' } }
    }
  }
}
</script>

<style></style>
